import { ref, watch } from 'vue';
import { useStore } from '@/store/useStore';

export default {
  setup() {
    const store = useStore();
    const renewal = ref(store.state.persistent.renewal);
    const items: { value: boolean; label: string }[] = [
      { value: true, label: '新UI' },
      { value: false, label: '旧UI' },
    ];

    const changeHandler = (value: boolean) => {
      store.commit('setRenewal', value);
      window.scrollTo({ top: 0 });
    };

    watch(
      () => store.state.persistent.renewal,
      (newVal) => {
        renewal.value = newVal;
      },
    );

    return {
      renewal,
      items,
      changeHandler,
    };
  },
};
