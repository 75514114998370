<template>
  <div>
    <v-container>
      <slot name="main">
        <!-- メインコンテンツが入る -->
      </slot>
    </v-container>
    <my-footer />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'nuxt-property-decorator';
import MyFooter from '@/components/base/my-footer.vue';

/**
 * 通常のレイアウト
 * /frontend/layouts/default.vue に floatingHeader として登録する事でヘッダーのレイアウトを変更できる
 */
@Component({ components: { MyFooter } })
export default class LayoutNormal extends Vue {}
</script>
